import en from './en'
import nb from './nb'
import sv from './sv'
import fi from './fi'
import fr from './fr'
import dk from './dk'
import i18n from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'

i18n
  .use(LngDetector)
  .init({
    resources: {
      en: { translation: en },
      sv: { translation: sv },
      nb: { translation: nb },
      fi: { translation: fi },
      dk: { translation: dk },
      fr: { translation: fr }
    },
    fallbackLng: 'en',
    compatibilityJSON: 'v3',
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      skipOnVariables: false
    },
    react: {
      useSuspense: false,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default'
    },
    returnNull: false
  })
  .catch(console.warn)

// You actually can't look up languages from i18n (wth?)
export const supportedLanguages = [
  // Language names are in the local language, so no matter the selected language you can identify "your" language
  // i.e. they should _not_ be translated
  { code: 'nb', name: 'Norsk' },
  { code: 'en', name: 'English' },
  { code: 'fi', name: 'Suomii' },
  { code: 'dk', name: 'Dansk' },
  { code: 'sv', name: 'Svenska' },
  { code: 'fr', name: 'Français' }
]
